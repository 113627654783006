import { Box, Container, Paragraph } from '@components'
import Image from 'next/image';
import React from 'react'
import SectionSeperator from '../layout/section-seperator';



const WastedFoodSection = () => {
    return (

        <SectionSeperator className='bg-[#F5F5F5] py-16'>
            <Container>
                <Box className='flex items-center justify-around gap-12'>
                    <Image className='rounded-full' objectFit='cover' layout='fixed' src="/images/home/1.png" alt='1' width={474} height={474} />
                    <Paragraph className='font-bold text-4xl text-[#1C6162] leading-[60px] text-center flex-1 px-10'>MORE THAN 1/3 OF ALL FOOD IS WASTED</Paragraph>
                </Box>
            </Container>
        </SectionSeperator >

    )
}

export default WastedFoodSection